import { Module } from 'vuex'
import StateTrackingEvaluation from './types'

import state from './state'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state,
  getters,
  mutations
} as Module<StateTrackingEvaluation, any>
